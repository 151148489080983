import React, { useEffect, useState } from 'react';
import LogoPlatformByEnersem from '@/components/core/logo/LogoPlatformByEnersem';
import { makeStyles } from 'tss-react/mui';
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Lock, User, Eye, EyeOff } from 'react-feather';
import { useRouter } from 'next/router';
import { getCsrfToken, getProviders, signIn } from 'next-auth/react';
import { GetServerSideProps } from 'next';
import { withLoggedInRedirect } from '@/server/withLoggedInRedirect';
import { toast } from 'react-toastify';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    background: theme.palette.background.light,
  },
  paper: {
    padding: 40,
    minWidth: 400,
    minHeight: 300,
    // @ts-ignore
    background: `${theme.palette.background.dark}!important`,
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  signUp: {
    marginTop: 20,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  button: {
    width: '100%',
    marginTop: 20,
    fontSize: 14,
  },
  loaderBtn: {
    color: theme.palette.primary.contrastText,
  },
  forgotPassword: {
    cursor: 'pointer',
    paddingTop: 20,
    width: '100%',
    textAlign: 'right',
  },
  register: {
    cursor: 'pointer',
    paddingTop: 20,
    width: '100%',
    textAlign: 'left',
  },
}));

export default function Login(props) {
  const { t } = useTranslation('common');
  const { csrfToken } = props;
  const { classes } = useStyles();
  const router = useRouter();
  const { callbackUrl } = router.query;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isFirstAccess) {
      setIsFirstAccess(false);
      router.push('/first-access');
    }
  }, [isFirstAccess]);
  useEffect(() => {
    if (redirectUrl) {
      setRedirectUrl(null);
      router.push(redirectUrl);
    }
  }, [redirectUrl]);

  function onSignIn(e) {
    e.preventDefault();
    setLoader(true);

    signIn('credentials', {
      username: username,
      password: password,
      redirect: false,
    }).then(({ error }) => {
      if (error) {
        toast.warning('Username e/o password errati', {
          position: 'bottom-left',
        });
        setLoader(false);
        return;
      }
      router.push((callbackUrl as string) ?? '/');
    });
  }

  return (
    <div className={classes.root}>
      <LogoPlatformByEnersem logoWidth={64} redirect sidebarOpen />

      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.title}>
          {t('login_page.title')}
        </Typography>
        <form onSubmit={(e) => onSignIn(e)} className={classes.form}>
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <br />
          <TextField
            id="username"
            label="Username"
            required
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <User size={16} />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            // @ts-ignore
            size="large"
            fullWidth
          />
          <TextField
            id="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            sx={{ m: 1 }}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock size={16} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '100%', marginTop: 16 }}
            // @ts-ignore
            size="large"
            fullWidth
          />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.register}>
                <a href="https://enersem.eu/software">
                  {t('login_page.missing_account')}
                </a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.forgotPassword}
                onClick={() => router.push('/email-reset-password')}
              >
                {t('login_page.lost_password')}
              </Typography>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={false}
            className={classes.button}
            data-testid="submit"
          >
            {!loader ? (
              t('login_page.login')
            ) : (
              <CircularProgress size={16} className={classes.loaderBtn} />
            )}
          </Button>
        </form>
      </Paper>

      {/*<Typography className={classes.signUp}>
        Non hai un account? Registrati
      </Typography>*/}
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = withLoggedInRedirect(
  async (context) => {
    return {
      props: {
        ...(await serverSideTranslations(context.locale, ['common'])),
        csrfToken: await getCsrfToken(context),
        providers: await getProviders(),
        disableGTM: true,
      },
    };
  }
);
